<template>
  <div>
    <v-btn
      @click="commands[type](parameters)"
      color="onSurface"
      :ripple="false"
      icon
    >
      <v-icon small>
        {{ icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    parameters: Object,
    isActive: Object,
    commands: Object,
    type: String,
    icon: String,
  },
};
</script>

<style></style>
