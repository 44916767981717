<template>
  <v-card v-if="editor" flat class="transparent">
    <v-card-title v-if="!readOnly" class="pa-0 mb-4">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <v-container fluid>
          <v-row>
            <text-format-button
              v-for="item in textTools"
              :key="item.type + item.icon"
              :type="item.type"
              :parameters="item.parameters"
              :icon="item.icon"
              :isActive="isActive"
              :commands="commands"
            />

            <v-file-input
              class="ma-0 pa-0"
              accept="image/*"
              v-model="tempImageFile"
              hide-input
              style="font-size: 18px !important"
              @change="addImage(commands)"
            />

            <tiptap-tool-button
              v-for="item in editorTools"
              :key="item.type + item.icon"
              :commands="commands"
              :parameters="item.parameters"
              :type="item.type"
              :icon="item.icon"
            />
          </v-row>
        </v-container>
      </editor-menu-bar>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-card
        class="pa-2"
        :outlined="!readOnly"
        flat
        :color="readOnly ? 'transparent' : ''"
      >
        <editor-content
          class="editor__content onSurface--text"
          :editor="editor"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import "@/styles/tiptapStyle.css";
import "@/styles/tiptapStyle.scss";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";

import TextFormatButton from "./Tools/TextFormatButton.vue";
import TiptapToolButton from "./Tools/TiptapToolButton.vue";
export default {
  components: {
    EditorContent,
    EditorMenuBar,
    TextFormatButton,
    TiptapToolButton,
  },
  props: {
    description: Array,
    menubar: Boolean,
    readOnly: Boolean,
    id: String,
  },
  data() {
    return {
      toggle_heading: null,
      toggle_multiple: [],
      swMenubar: this.menubar,
      linkUrl: null,
      linkMenuIsActive: false,
      editor: null,
      tempImageFile: null,

      editorTools: [
        { type: "undo", icon: "mdi-undo" },
        { type: "undo", icon: "mdi-redo" },
      ],
      textTools: [
        // {
        //   type: "link",
        //   icon: "mdi-link",
        //   onClick: () => {
        //     this.showLinkMenu(getMarkAttrs("link"));
        //   },
        // },
        { type: "bold", icon: "mdi-format-bold" },
        { type: "italic", icon: "mdi-format-italic" },
        { type: "underline", icon: "mdi-format-underline" },
        { type: "strike", icon: "mdi-format-strikethrough" },
        { type: "code", icon: "mdi-xml" },
        { type: "paragraph", icon: "mdi-format-paragraph" },
        { type: "bullet_list", icon: "mdi-format-list-bulleted" },
        { type: "ordered_list", icon: "mdi-format-list-numbered" },
        // { type: "blockquote", icon: "mdi-format-quote-open" },

        {
          type: "heading",
          icon: "mdi-format-header-1",
          parameters: { level: 1 },
        },
        {
          type: "heading",
          icon: "mdi-format-header-2",
          parameters: { level: 2 },
        },
        {
          type: "heading",
          icon: "mdi-format-header-3",
          parameters: { level: 3 },
        },
      ],
    };
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    async addImage(commands) {
      // if (this.tempImageFile && this.tempImageFile.size > 2000000) {
      //   this.$toasted.global.error("이미지는 2 MB 미만으로 사용해주세요!");
      //   return;
      // }
      const url = URL.createObjectURL(this.tempImageFile);
      commands.image({ src: url, title: this.tempImageFile.name });
      this.$emit("addImage", this.tempImageFile);
      this.tempImageFile = null;
    },
    initializeEditor() {
      this.editor = new Editor({
        editable: !this.readOnly,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({
            levels: [1, 2, 3],
            HTMLAttributes: {
              class: "wooz-header",
            },
          }),
          new Link({ rel: "", target: "_blank" }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code({}),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
        ],
        content: {
          type: "doc",
          content: this.description,
        },

        onUpdate: ({ getJSON }) => {
          this.$emit("updateContent", getJSON().content);
        },
      });
    },
    clearContent() {
      this.editor.clearContent();
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url, target: "_blank" });
      this.hideLinkMenu();
    },
  },
};
</script>

<style></style>
