<template>
  <div>
    <v-btn
      @click.prevent="commands[type](parameters)"
      :color="isActive[type](parameters) ? 'onSurface' : 'surface darken-1'"
      :ripple="false"
      icon
    >
      <v-icon small>{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    parameters: Object,
    isActive: Object,
    commands: Object,
    type: String,
    icon: String,
  },
};
</script>

<style></style>
