<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            포트폴리오 관리
          </v-card-title>
          <v-card-subtitle>
            포트폴리오 정보를 관리하는 페이지입니다.
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="pa-4 rounded-lg" outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" icon @click="list" :disabled="fetchLoading">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              @click="openDialog()"
              :ripple="false"
              color="primary"
              class="rounded-lg body-2 font-weight-bold"
              depressed
            >
              포트폴리오 추가
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-iterator
              class="body-2"
              :loading="fetchLoading"
              :items="items"
              :page.sync="page"
              :options.sync="options"
              :server-items-length="totalCount"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              no-data-text="검색된 데이터가 없습니다"
              no-results-text="매칭되는 기록이 없습니다"
              loading-text="로딩 중입니다."
              disable-sort
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col cols="12" v-if="fetchLoading" class="text-center">
                    <v-row>
                      <v-col v-for="index in 4" :key="index" cols="6" md="3">
                        <v-skeleton-loader class="rounded-lg" type="card" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-else
                    v-for="item in props.items"
                    :key="item.name"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <div @click="openDialog(item)">
                      <portfolio-card :content="item" />
                    </div>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card-text>
          <v-pagination v-model="page" :length="pageCount" />
        </v-card>
        <admin-portfolio-dialog
          :dialog="dialog"
          :content="item"
          @update:dialog="dialog = $event"
          @reloadList="list()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";
import PortfolioCard from "@/components/Card/PortfolioCard.vue";
import AdminPortfolioDialog from "@/components/Dialog/Admin/Portfolio/AdminPortfolioDialog.vue";

export default {
  components: { PortfolioCard, AdminPortfolioDialog },

  beforeCreate() {
    setMeta({
      title: "포트폴리오 관리",
      description: "메이드올 관리자페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
    search(n, o) {
      if (n !== o) this.list();
    },
  },
  methods: {
    list() {
      this.fetchLoading = true;
      this.$axios
        .get("admin/portfolios", {
          params: {
            offset: this.page > 0 ? (this.page - 1) * this.itemsPerPage : 0,
            limit: this.itemsPerPage,
            order: this.options.sortBy[0],
            sort: this.options.sortDesc[0] ? "desc" : "asc",
            search: this.search,
          },
        })
        .then(({ data }) => {
          this.totalCount = data.totalCount;
          this.items = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
    openDialog(content) {
      this.item = content;
      this.dialog = true;
    },
  },
  data() {
    return {
      fetchLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      totalCount: 0,
      items: [],
      item: {},
      dialog: false,
    };
  },
};
</script>
