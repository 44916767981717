<template>
  <v-dialog
    class="elevation-0"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    max-width="1080px"
    persistent
  >
    <v-card flat class="rounded-xl">
      <v-card-title class="font-weight-bold">
        <v-dialog v-model="dialogDelete" max-width="320px">
          <v-card class="rounded-xl text-center pa-2" flat>
            <v-card-title class="title font-weight-bold">
              선택한 항목을 삭제하겠습니까?
            </v-card-title>
            <v-card-subtitle class="ma-0">
              해당 항목이 즉시 삭제됩니다. 이 동작은 실행 취소할 수 없습니다.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary rounded-lg" depressed @click="closeDelete">
                취소
              </v-btn>
              <v-btn
                color="surface rounded-lg"
                depressed
                @click="deleteItemConfirm"
              >
                삭제
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          color="error"
          class="rounded-lg font-weight-bold"
          depressed
          @click="deleteItem(content)"
          icon
        >
          <feather type="trash" />
        </v-btn>
        <v-spacer />

        <v-btn
          @click="editedItem.locked = !editedItem.locked"
          color="grey darken-1"
          icon
        >
          <feather v-if="editedItem.locked" type="lock" />
          <feather v-else type="unlock" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.title"
                class="title font-weight-bold onSurface--text"
                hide-details
                style="max-width: 480px"
                solo
                dense
                flat
                background-color="surface"
                label="제목"
              />
            </v-col>
            <v-col cols="4" md="2" align-self="center"> 생성일 </v-col>
            <v-col cols="8" md="10">
              {{ editedItem.createdAt | moment("YYYY-MM-DD HH:mm") }}
            </v-col>
            <v-col cols="4" md="2" align-self="center"> 마지막 수정 </v-col>
            <v-col cols="8" md="10">
              {{ editedItem.updatedAt | moment("YYYY-MM-DD HH:mm") }}
              |
              <span>
                {{ editedItem.uid || $store.getters["auth/user/GET_USER"].uid }}
                <v-btn
                  v-clipboard:copy="editedItem.uid"
                  v-clipboard:success="
                    () => {
                      $toasted.global.success('클립보드에 저장했습니다.');
                    }
                  "
                  v-clipboard:error="
                    () => {
                      $toasted.global.error('복사를 실패했습니다.');
                    }
                  "
                  small
                  color="grey lighten-1"
                  icon
                >
                  <feather size="18" type="copy" />
                </v-btn>
              </span>
            </v-col>

            <v-col cols="4" md="2" align-self="center"> 커버 이미지 </v-col>
            <v-col cols="8" md="4">
              <v-hover v-slot="{ hover }" close-delay="100">
                <v-card
                  class="surface rounded-lg"
                  max-height="320"
                  max-width="320"
                  flat
                >
                  <v-img
                    :aspect-ratio="4 / 3"
                    :src="tempUrl || editedItem.imageURL"
                  >
                    <div
                      v-if="hover"
                      class="justify-center align-center d-flex"
                      style="height: 100%; flex-direction: column"
                    >
                      <v-btn class="ma-1" small @click="$refs.uploader.click()">
                        업로드
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="onFileChanged"
                      />
                      <v-btn class="ma-1" small> 편집 </v-btn>
                    </div>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="4" md="2" align-self="center" class="py-2">
                  소요 비용
                </v-col>
                <v-col cols="8" md="10" align-self="center" class="py-2">
                  <v-text-field
                    v-model="editedItem.options.cost"
                    max-width="100"
                    class="body-1 font-weight-bold onSurface--text"
                    hide-details
                    solo
                    dense
                    flat
                    background-color="surface"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="2" align-self="center" class="py-2">
                  작업 방식
                </v-col>
                <v-col cols="8" md="10" align-self="center" class="py-2">
                  <v-text-field
                    v-model="editedItem.options.method"
                    max-width="100"
                    class="body-1 font-weight-bold onSurface--text"
                    hide-details
                    solo
                    dense
                    flat
                    background-color="surface"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="2" align-self="center" class="py-2">
                  작업 소재
                </v-col>
                <v-col cols="8" md="10" align-self="center" class="py-2">
                  <v-text-field
                    v-model="editedItem.options.material"
                    max-width="100"
                    class="body-1 font-weight-bold onSurface--text"
                    hide-details
                    solo
                    flat
                    dense
                    background-color="surface"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="2" align-self="center" class="py-2">
                  표면 가공
                </v-col>
                <v-col cols="8" md="10" align-self="center" class="py-2">
                  <v-text-field
                    v-model="editedItem.options.postprocess"
                    max-width="100"
                    class="body-1 font-weight-bold onSurface--text"
                    hide-details
                    solo
                    dense
                    flat
                    background-color="surface"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="2" align-self="center" class="py-2">
                  소요 기간
                </v-col>
                <v-col cols="8" md="10" align-self="center" class="py-2">
                  <v-text-field
                    v-model="editedItem.options.time"
                    max-width="100"
                    class="body-1 font-weight-bold onSurface--text"
                    hide-details
                    solo
                    dense
                    flat
                    background-color="surface"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" align-self="center"> 게시글 </v-col>
            <v-col cols="12" class="pa-0">
              <tiptap-editor
                ref="tiptapEditor"
                @updateContent="updateContent"
                @addImage="addImage"
                :description="editedItem.content"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="rounded-lg"
          text
          @click="close"
          :ripple="false"
        >
          취소
        </v-btn>
        <v-btn
          color="primary"
          class="rounded-lg font-weight-bold"
          depressed
          :loading="buttonLoading"
          :block="$vuetify.breakpoint.smAndDown"
          @click="save"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TiptapEditor from "@/components/Editor/TiptapEditor.vue";
import firestorageUtils from "@/utils/firestorage";

import _ from "lodash";
import constants from "@/utils/constants";

export default {
  components: { TiptapEditor },
  props: {
    dialog: Boolean,
    content: Object,
  },
  data() {
    return {
      contentImageNames: [],
      contentNewImageNames: [],
      contentNewImages: [], // Image Files
      newContentImages: [],

      tempImageFile: null,
      buttonLoading: false,

      dialogDelete: false,
      contentReadOnly: true,
      editedItem: {},
    };
  },
  created() {
    this.editedItem = _.cloneDeep(constants.portfolioInterface);
  },
  watch: {
    dialog(val) {
      val ? this.openItem() : this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    openItem() {
      this.editedItem = Object.assign(
        _.cloneDeep(constants.portfolioInterface),
        this.content,
      );
    },
    close() {
      this.$emit("update:dialog", false);
      this.tempImageFile = null;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(constants.portfolioInterface);
        console.log(this.editedItem.options);
      });
    },
    
    deleteItem() {
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      if (this.editedItem.id) {
        await this.$axios.delete("admin/deletePortfolio/" + this.editedItem.id);
      }
      this.closeDelete();
      this.close();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$emit("reloadList");
    },

    onFileChanged(e) {
      this.tempImageFile = e.target.files[0];
      if (this.tempImageFile.type.split("/")[0] != "image")
        this.$toasted.global.error("이미지 파일만 등록 가능합니다.");
      this.tempUrl = URL.createObjectURL(this.tempImageFile);
    },
    updateContent(json) {
      this.editedItem.content = json;
      this.contentNewImageNames = [];
      this.contentImageNames = [];
      this.checkNewImages(this.editedItem.content);
    },
    addImage(image) {
      this.contentNewImages.push(image);
    },

    checkNewImages(arr) {
      if (arr == undefined) return;
      // eslint-disable-next-line no-unused-vars
      arr.reduce((acc, cur, index, array) => {
        switch (cur.type) {
          case "paragraph":
            return this.checkNewImages(cur.content);
          case "text":
            break;
          case "image":
            if (cur.attrs) {
              if (
                !cur.attrs.src.includes(
                  "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com",
                )
              ) {
                this.contentNewImageNames.push(cur.attrs.title);
              }
              this.contentImageNames.push(cur.attrs.title);
            }
        }
      }, arr);
    },

    findImageAndChangeURL(arr, ImageName, ImageURL = "") {
      if (arr == undefined) return;
      // eslint-disable-next-line no-unused-vars
      arr.reduce((acc, cur, index, array) => {
        switch (cur.type) {
          case "paragraph":
            return this.findImageAndChangeURL(cur.content, ImageName, ImageURL);
          case "text":
            break;
          case "image":
            if (cur.attrs) {
              if (
                cur.attrs.alt === ImageName ||
                cur.attrs.title === ImageName
              ) {
                cur.attrs.src = ImageURL;
              }
            }
            break;
        }
      }, arr);
    },
    async save() {
      this.buttonLoading = true;
      this.$toasted.global.notice("업로드 중입니다.");

      if (!this.editedItem.id) {
        const portfolio = await this.$axios.put("admin/addPortfolio/", {
          uid: this.$store.getters["auth/user/GET_USER"].uid,
          item: this.editedItem,
        });
        this.editedItem.id = portfolio.data.id;
        this.editedItem.createdAt = portfolio.data.data.createdAt;
      }

      this.contentNewImageNames = [];
      this.contentImageNames = [];
      this.checkNewImages(this.editedItem.content);

      this.newContentImages = this.editedItem.contentImages.filter((old) => {
        return this.contentImageNames.some((newContentImage) => {
          if (newContentImage === old.imageName) {
            return old;
          }
        });
      });

      try {
        await this.uploadThumbnail();
        await this.uploadContentImages();
        this.editedItem.contentImages = this.newContentImages;

        await this.$axios.put("admin/updatePortfolio/" + this.editedItem.id, {
          uid: this.$store.getters["auth/user/GET_USER"].uid,
          item: this.editedItem,
        });

        this.$toasted.global.success("업로드 완료!");
        this.close();
        this.buttonLoading = false;
      } catch (e) {
        this.$toasted.global.error(e);
        this.close();
        this.buttonLoading = false;
        return;
      }
      this.$emit("reloadList");
    },

    async uploadContentImages() {
      if (this.contentNewImageNames.length == 0) return;

      for await (const image of this.contentNewImages) {
        if (!this.contentNewImageNames.includes(image.name)) continue;

        const result = await firestorageUtils.uploadImageFile({
          file: image,
          path: `Portfolios/${this.editedItem.id}/contents/${image.name}`,
        });

        const imageData = {
          filePath: result.filePath,
          imageURL: result.imageURL,
          imageName: image.name ?? "",
        };

        this.newContentImages.push(imageData);

        this.findImageAndChangeURL(
          this.editedItem.content,
          image.name,
          result.imageURL,
        );
      }
    },
    async uploadThumbnail() {
      if (this.tempImageFile == null) return;
      const result = await firestorageUtils.uploadImageFile({
        file: this.tempImageFile,
        path: `Portfolios/${this.editedItem.id}/thumbnail/${this.tempImageFile.name}`,
      });

      this.editedItem.filePath = result.filePath;
      this.editedItem.imageURL = result.imageURL;
    },
  },
};
</script>

<style></style>
